import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const MetaTags = () => {
  const location = useLocation();
  let title, description, image;

  switch (location.pathname) {
    case "/":
      title = "The Veil | DayZ RP Server";
      description =
        "Story and lore-driven DayZ Roleplay Server with imaginative low-fantasy and sci-fi elements, created and developed by roleplay veterans.";
      image = "https://i.ibb.co/PDLcvBs/The-Veil-Day-Z-RP-Server.png";
      break;
    case "/rules":
      title = "Rules | The Veil DayZ RP Server";
      description = "Learn about the rules and guidelines for The Veil DayZ Roleplay Server.";
      image = "https://i.ibb.co/PDLcvBs/The-Veil-Day-Z-RP-Server.png";
      break;
    // Add more cases for other routes
    case "/whitelist-application":
      title = "Whitelist Application | The Veil DayZ RP Server";
      description =
        "Apply to join our immersive DayZ roleplay community. Submit your whitelist application and become part of The Veil's unique story-driven experience.";
      image = "https://i.ibb.co/PDLcvBs/The-Veil-Day-Z-RP-Server.png";
      break;
    default:
      title = "The Veil | DayZ RP Server";
      description =
        "Story and lore-driven DayZ Roleplay Server with imaginative low-fantasy and sci-fi elements, created and developed by roleplay veterans.";
      image = "https://i.ibb.co/PDLcvBs/The-Veil-Day-Z-RP-Server.png";
  }

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={`https://www.theveildayz.com${location.pathname}`} />
      <meta property="og:image:width" content="2048" />
      <meta property="og:image:height" content="1024" />
      <meta property="og:type" content="website" />
      <link rel="canonical" href={`https://www.theveildayz.com${location.pathname}`} />

      {/* <!-- Google / Search Engine Tags --> */}
      <meta itemProp="name" content={title} />
      <meta itemProp="description" content={description} />
      <meta itemProp="image" content={image} />

      {/* <!-- Twitter Meta Tags --> */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
    </Helmet>
  );
};

export default MetaTags;
