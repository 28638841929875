import React, { useEffect, useState } from "react";
import "react-notion-x/src/styles.css";

import Box from "@mui/material/Box";
import Layout from "../../components/Layout";
import bgImage from "../../assets/rulesbg.png";
import "./styles.css";
import { Grid, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import { NotionRenderer } from "react-notion-x";

const layoutStyles = {
  background: `url(${bgImage}) no-repeat center`,
};
const styles = (theme) => ({
  height: "90vh",
  overflowY: "scroll",
  // margin: '0px auto',
  background: "white",
  textAlign: "left",
  padding: "48px 32px",
  boxShadow: "0px 1px 10px rgba(0,0,0,0.8)",
  backgroundColor: "rgba(0,0,0,0.93)",
  borderRadius: 1,
  [theme.breakpoints.down("md")]: {
    height: "auto",
    overflow: "auto",
    padding: "24px 0",
    "& .notion-page": {
      padding: 0,
    },
  },
});

const Rules = (props) => {
  const [blockMap, setBlockMap] = useState();
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up("md"));
  useEffect(async () => {
    const data = await fetch("/.netlify/functions/notion").then((res) => res.json());
    setBlockMap(data);
  }, []);

  return (
    <Layout {...props} styles={layoutStyles} header="Rules">
      {blockMap && (
        <Grid container spacing={{ xs: 2, md: 3, lg: 12 }} sx={{ px: isMobile ? 3 : 12 }}>
          <Grid item xs={12}>
            <Box sx={styles}>
              <NotionRenderer recordMap={blockMap} darkMode={false} />
            </Box>
          </Grid>
        </Grid>
      )}
    </Layout>
  );
};

export default Rules;
