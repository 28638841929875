import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/styles";
const styles = (theme) => ({
  //  height: '90vh',
  paddingTop: "91px",
  marginBottom: "90px",
  minHeight: "100vh",
  height: "auto",
  width: "100%",
  display: "flex",
  backgroundRepeat: "no-repeat",
  boxShadow: "inset 0em 0em 2em 20px rgb(0 0 0)",
  [theme.breakpoints.down("md")]: {
    flexWrap: "wrap",
    height: "auto",
    minHeight: "100vh",
    display: "block",
  },
});
const Layout = ({ children, header, ...props }) => {
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box
      {...props}
      styles={props.styles}
      sx={(theme) => ({ ...styles(theme), ...props.styles })}
      justifyContent="center"
    >
      {header && (
        <h1
          style={{
            marginLeft: isMobile ? 24 : "56px",
            color: "white",
            maxWidth: "16%",
            minWidth: "130px",
            textAlign: "left",
            ...props.headerStyles,
          }}
        >
          {header}
        </h1>
      )}
      {children}
    </Box>
  );
};
export default Layout;
