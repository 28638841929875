import Button from "@mui/material/Button";
import CollectionsIcon from "@mui/icons-material/Collections";
import InfoIcon from "@mui/icons-material/Info";
import PlaylistAddCheckCircleIcon from "@mui/icons-material/PlaylistAddCheckCircle";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import { DiscordIcon } from "./icons";
import { DISCORD_URL } from "../constants";
import { Link } from "react-router-dom";

const MenuButtons = ({ setDrawerOpen }) => {
  const onClick = setDrawerOpen ? () => setDrawerOpen(false) : () => ({});
  return (
    <>
      <Button
        variant="text"
        color="inherit"
        sx={{ my: 1, mx: 1.5, fontWeight: "bold" }}
        endIcon={<CollectionsIcon />}
        LinkComponent={Link}
        to={{ pathname: "/", hash: "#gallery" }}
        onClick={onClick}
      >
        GALLERY
      </Button>
      <Button
        variant="text"
        color="inherit"
        LinkComponent={Link}
        to={{ pathname: "/", hash: "#about" }}
        sx={{ my: 1, mx: 1.5, fontWeight: "bold" }}
        endIcon={<InfoIcon />}
        onClick={onClick}
      >
        ABOUT
      </Button>
      <Button
        variant="text"
        color="inherit"
        LinkComponent={Link}
        to={{ pathname: "/", hash: "#rules" }}
        sx={{ my: 1, mx: 1.5, fontWeight: "bold" }}
        endIcon={<PlaylistAddCheckCircleIcon />}
        onClick={onClick}
      >
        Rules
      </Button>
      <Button
        variant="text"
        color="inherit"
        LinkComponent={Link}
        to={{ pathname: "/", hash: "#faqs" }}
        sx={{ my: 1, mx: 1.5, fontWeight: "bold" }}
        endIcon={<InfoIcon />}
        onClick={onClick}
      >
        FAQs
      </Button>
      <Button
        variant="text"
        color="inherit"
        href="/whitelist-application"
        sx={{ my: 1, mx: 1.5, fontWeight: "bold" }}
        endIcon={<FactCheckIcon />}
        onClick={onClick}
      >
        WHITELIST
      </Button>
      <Button
        variant="outlined"
        color="inherit"
        href={DISCORD_URL}
        target="_blank"
        sx={{ my: 1, mx: 1.5, color: "#5865F2", fontWeight: "bold" }}
        endIcon={<DiscordIcon viewBox="0 0 32 32" fill="#5865F2" />}
        onClick={onClick}
      >
        DISCORD
      </Button>
    </>
  );
};

export default MenuButtons;
