import { Box, Button, CircularProgress, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import WhitelistForm from "./WhitelistForm";
import StepOne from "./steps/stepOne";
import StepTwo from "./steps/stepTwo";
import useWhitelistStore from "./store";
import { submitWhitelist } from "../../utils/api";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(3),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ["Join The Veil Discord", "Verify Discord Membership", "Fill Out Form", "Success"];
}

function getMobileSteps() {
  return ["Join Discord", "Verify Discord", "Form", "Success"];
}

function getStepContent(step) {
  const { handleDiscordLogin } = useWhitelistStore();
  switch (step) {
    case 0:
      return <StepOne />;
    case 1:
      return <StepTwo handleDiscordLogin={handleDiscordLogin} />;
    case 2:
      return <WhitelistForm />;
    case 3:
      return (
        <Box
          sx={{
            mt: 4,
            px: 3,
            py: 4,
            borderColor: "success.main",
            borderWidth: 1,
            borderStyle: "solid",
            color: "success.main",
            borderRadius: 2,
          }}
        >
          <Typography variant="h5" component="h2" gutterBottom>
            Congratulations!
          </Typography>
          <Typography variant="body1">
            You have successfully submitted your whitelist application. You will receive a DM from
            The Veil Discord Bot upon review.
          </Typography>
        </Box>
      );
    default:
      return "Unknown step";
  }
}

function validateStepTwo(data) {
  // Replace with actual validation logic for step two
  // console.log("IS DISCORD VERIFIED", data.isDiscordVerified);
  // console.log("IS DISCORD MEMBER", data.isDiscordJoinedMember);
  return data.isDiscordVerified && data.isDiscordJoinedMember;
}

function validateStepThree(data) {
  // Replace with actual validation logic for step three
  // console.log("IS FORM VALID validation", data);
  return data.formIsValid;
}

async function finalFormSubmission() {
  const formData = useWhitelistStore.getState().whitelistFormData;
  const discordUser = useWhitelistStore.getState().discordUser;

  // console.log("FORM DATA", { formData, discordUser });
  const { data } = await submitWhitelist({ formData, discordUser });
  // console.log("WHITELIST SUBMISSION RESPONSE", data);
  // await new Promise((resolve) => setTimeout(resolve, 5000));

  return true;
}

export default function WhitelistStepper() {
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up("md"));
  const classes = useStyles();
  const activeStep = useWhitelistStore((state) => state.activeStep);
  const formIsSubmitting = useWhitelistStore((state) => state.formIsSubmitting);
  const steps = isMobile ? getMobileSteps() : getSteps();
  const stepData = useWhitelistStore((state) => state.stepData);
  const setActiveStep = useWhitelistStore((state) => state.setActiveStep);
  // const setStepData = useWhitelistStore((state) => state.setStepData);

  const validateStep = (step) => {
    switch (step) {
      case 0:
        return true;
      case 1:
        return validateStepTwo(stepData);
      case 2:
        return validateStepThree(stepData);
      default:
        return false;
    }
  };

  const handleNext = async () => {
    if (activeStep === steps.length - 2) {
      try {
        useWhitelistStore.setState({ formIsSubmitting: true });
        await finalFormSubmission();
      } catch (error) {
        console.error("Error during form submission:", error);
        alert("An error occurred during form submission. Please try again.");
        return;
      } finally {
        useWhitelistStore.setState({ formIsSubmitting: false });
      }
    }

    let isValid = validateStep(activeStep);

    if (isValid) {
      setActiveStep(activeStep + 1);
    } else {
      alert("Please complete the current step before proceeding.");
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  // const handleReset = () => {
  //   setActiveStep(0);
  // };

  return (
    <div className={classes.root}>
      <Stepper
        activeStep={activeStep}
        sx={
          isMobile
            ? {
                "& .MuiStepLabel-label": {
                  fontSize: "12px",
                },
                "& .MuiSvgIcon-root": {
                  width: "0.8em",
                },
                "& .MuiStepConnector-root": {
                  display: "none",
                },
              }
            : {}
        }
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <Box sx={{ py: isMobile ? 2 : 4, mb: 8 }}>
        <div>
          <Box className={classes.instructions} sx={{ py: isMobile ? 2 : 4 }}>
            {getStepContent(activeStep)}
          </Box>
          {activeStep !== steps.length - 1 && (
            <div>
              <Button
                disabled={activeStep === 0 || formIsSubmitting}
                onClick={handleBack}
                className={classes.button}
                size="large"
                sx={{ mr: 2 }}
              >
                Back
              </Button>

              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                className={classes.button}
                size="large"
                disabled={!validateStep(activeStep) || formIsSubmitting}
              >
                {formIsSubmitting ? (
                  <>
                    <CircularProgress size={24} /> Submitting...
                  </>
                ) : activeStep === steps.length - 2 ? (
                  "Submit Application"
                ) : (
                  "Next"
                )}
              </Button>
            </div>
          )}
        </div>
      </Box>
    </div>
  );
}
