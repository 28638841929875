import Layout from "../../components/Layout";
import bgImage from "../../assets/topbg.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, Button, Typography } from "@mui/material";
import { DiscordIcon } from "../../components/icons";
import { DISCORD_URL } from "../../constants";
const styles = {
  justifyContent: "center",
  background: `url(${bgImage}) no-repeat center`,
  backgroundSize: "cover",
  paddingTop: "91px",
  display: "flex !important",
};
const buttonStyles = (theme) => ({
  height: "42px",
  width: "42px",
  alignSelf: "end",
  marginBottom: "16px",
  color: "rgba(255,255,255,0.8)",
  position: "absolute",
  bottom: 0,
  [theme.breakpoints.down("md")]: {
    display: "none !important",
  },
});
const Top = (props) => {
  return (
    <Layout {...props} styles={styles}>
      <Box
        sx={{
          alignItems: "center",
          p: 2,
          alignContent: "center",
          display: "grid",
        }}
      >
        <Typography sx={{ color: "white", mb: 2, mt: 13 }} variant="body1">
          A DayZ Roleplay Community
        </Typography>
        <Button
          variant="outlined"
          color="inherit"
          href={DISCORD_URL}
          target="_blank"
          sx={{ my: 1, mx: 1.5, color: "#ffffff", fontWeight: "bold" }}
          endIcon={<DiscordIcon viewBox="0 0 32 32" fill="#5865F2" />}
        >
          Join Today!
        </Button>
      </Box>
      <Button sx={buttonStyles} variant="text" href="#gallery">
        <KeyboardArrowDownIcon sx={{ fontSize: "56px" }} />
      </Button>
    </Layout>
  );
};

export default Top;
