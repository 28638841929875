import { useState, useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import logo from "../assets/LOGO.png";
import { useTheme } from "@mui/material/styles";
import { Divider, IconButton, useMediaQuery } from "@mui/material";

import MenuOpen from "@mui/icons-material/MenuOpen";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MenuButtons from "./MenuButtons";
import { Link } from "react-router-dom";

export const AppBar = ({ hideLogo }) => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [small, setSmall] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () => setSmall(window.pageYOffset > 500));
    }
  }, []);

  return (
    <MuiAppBar
      position="static"
      color="default"
      elevation={0}
      sx={{
        backgroundColor: small ? "rgba(0, 0, 0, 0.8)" : "rgba(0, 0, 0, 0.3)",
        color: "white",
        position: "fixed",
        zIndex: 10,
      }}
    >
      <Toolbar sx={{ flexWrap: "wrap" }}>
        {!hideLogo && (
          <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
            <Button
              variant="text"
              color="inherit"
              LinkComponent={Link}
              to={{ pathname: "/", hash: "#top" }}
            >
              <img src={logo} style={{ width: 70, margin: "8px 16px" }} />
            </Button>
          </Typography>
        )}
        {isMobile ? (
          <IconButton onClick={() => setDrawerOpen(true)} aria-label="open drawer">
            <MenuOpen />
          </IconButton>
        ) : (
          <nav>
            <MenuButtons />
          </nav>
        )}
        {isMobile && (
          <Drawer
            sx={{
              width: "50vw",
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: "50vw",
                boxSizing: "border-box",
              },
            }}
            anchor="right"
            open={isDrawerOpen}
          >
            <div>
              <IconButton onClick={() => setDrawerOpen(false)}>
                <ChevronRightIcon />
              </IconButton>
            </div>
            <Divider />
            <MenuButtons setDrawerOpen={setDrawerOpen} />
          </Drawer>
        )}
      </Toolbar>
    </MuiAppBar>
  );
};
