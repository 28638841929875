import { create } from "zustand";
import storage from "../../utils/sessionStorage";
import api, { apiDiscordLogin } from "../../utils/api";

const useWhitelistStore = create((set) => ({
  discordUser: null,
  discordIsMember: false,
  formIsLoading: true,
  formIsSubmitting: false,
  stepData: {
    isDiscordJoinedMember: false,
    isDiscordVerified: false,
    formIsValid: false,
  },
  activeStep: 0,
  whitelistFormData: null,
  isCheckingDiscordMember: false,
  setWhitelistFormData: (data) => set({ whitelistFormData: data }),
  setStepData: (data) => set((state) => ({ stepData: { ...state.stepData, ...data } })),
  setActiveStep: (step) => set({ activeStep: step }),
  setIsDiscordJoinedMember: (isDiscordJoinedMember) => set({ isDiscordJoinedMember }),
  setIsDiscordVerified: (isDiscordVerified) => set({ isDiscordVerified }),
  setDiscordUser: (discordUser) => set({ discordUser }),
  setDiscordIsMember: (discordIsMember) => set({ discordIsMember }),
  setFormIsLoading: (formIsLoading) => set({ formIsLoading }),
  resetStore: (resetActiveStep = true) =>
    set({
      discordUser: null,
      discordIsMember: false,
      stepData: {
        isDiscordJoinedMember: false,
        isDiscordVerified: false,
        formIsValid: false,
      },
      ...(resetActiveStep ? { activeStep: 0 } : {}),
    }),
  //Actions
  handleDiscordLogin: async () => {
    try {
      const response = await apiDiscordLogin();
      const url = response.data.url;

      window.location.href = url;
    } catch (error) {
      console.error("Failed to login with Discord:", error);
    }
  },
  handleDiscordLogout: async () => {
    if (confirm("Are you sure want to log out of Discord?")) {
      storage.clearStorageValues();
      useWhitelistStore.getState().resetStore(false);
    }
  },
  handleDiscordIsMemberCheck: async () => {
    useWhitelistStore.setState({ isCheckingDiscordMember: true });
    const existingToken = storage.getToken();
    if (existingToken) {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      const response = await api.checkToken(existingToken.access_token);

      // useWhitelistStore.setState({ isDiscordJoinedMember: response.data.isMemberOfServer });
      useWhitelistStore
        .getState()
        .setStepData({ isDiscordJoinedMember: response.data.isMemberOfServer });
    }
    useWhitelistStore.setState({ isCheckingDiscordMember: false });
  },
}));

export default useWhitelistStore;
