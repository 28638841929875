import { ToggleButton, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Layout from "../../components/Layout";
import { useTheme } from "@mui/material/styles";
import bgImage from "../../assets/gallerybg.png";
import faqsData from "./faqs-data";
import { useState } from "react";
import { Box } from "@mui/system";

const styles = {
  justifyContent: "center",
  background: `url(${bgImage}) no-repeat top`,
  backgroundSize: "contain",
  paddingTop: "91px",
  color: "#232323",
  paddingBottom: "140px",
};
const boxStyles = (theme) => ({
  backgroundColor: "rgba(0,0,0,0.93)",
  boxShadow: "0px 1px 10px rgba(0,0,0,0.8)",
  p: 4,
  textAlign: "left",
  borderRadius: "4px",
  // height: "90%",
  width: "100%",
  overflowY: "auto",
  color: "white",
  [theme.breakpoints.down("md")]: {
    height: "auto",
    p: 2,
  },
});

const FAQs = (props) => {
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up("md"));
  const [expandAll, setExpandAll] = useState(false);
  const [expanded, setExpanded] = useState("panel0");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleChangeExpandAll = (value) => {
    setExpandAll(value);
    setExpanded(!value ? "panel0" : false);
  };
  return (
    <Layout {...props} styles={{ ...props.styles, ...styles }} header="FAQs">
      <Grid container spacing={{ xs: 2, md: 3, lg: 12 }} sx={{ px: isMobile ? 2 : 12 }}>
        <Grid item xs={12}>
          <Box sx={boxStyles}>
            <Typography variant="h4" sx={{ mb: 2 }} color="primary.main">
              Frequently Asked Questions
            </Typography>
            <Typography>
              In order to assist players navigating the world in which The Veil takes place, we have
              put together a few answers to some Frequently Asked Questions. Not all of this
              information is in-character knowledge, but is meant to guide your understanding of the
              server&apos;s lore, the Rift Events, Deer Isle, and the state of Earth on the server.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ position: "relative" }}>
          <ToggleButton
            value="check"
            selected={expandAll}
            onChange={() => {
              handleChangeExpandAll(!expandAll);
            }}
            sx={{
              background: "none",
              border: "none",
              position: "absolute",
              top: "41px",
              right: 0,
            }}
          >
            {expandAll ? "Collapse" : "Expand"} All
          </ToggleButton>
          {faqsData.map((faq, index) => (
            <Accordion
              key={faq.question + index}
              sx={{
                backgroundColor: "rgba(0,0,0,0.93)",
                backgroundImage: "linear-gradient(rgba(0,0,0,0.93), rgba(0,0,0,0.93))",
              }}
              expanded={expandAll ? true : expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}a-content`}
                id={`panel${index}a-content`}
              >
                <Typography
                  sx={{ fontSize: isMobile ? "18px" : "20px", textAlign: "left" }}
                  color="primary.main"
                >
                  {faq.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: "8px 16px 24px" }}>
                <Typography sx={{ textAlign: "left" }}>{faq.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>
      </Grid>
    </Layout>
  );
};

export default FAQs;
