import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { DiscordIcon } from "../../../components/icons";
import useWhitelistStore from "../store";
import { Logout } from "@mui/icons-material";
import { DISCORD_URL } from "../../../constants";
import { useTheme } from "@mui/styles";
import { Refresh } from "@mui/icons-material";

export default function StepTwo({ handleDiscordLogin }) {
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up("md"));
  const { handleDiscordLogout, handleDiscordIsMemberCheck } = useWhitelistStore.getState();

  const discordUser = useWhitelistStore((state) => state.discordUser);
  const isDiscordJoinedMember = useWhitelistStore((state) => state.stepData.isDiscordJoinedMember);
  const isCheckingDiscordMember = useWhitelistStore((state) => state.isCheckingDiscordMember);

  // console.log("CHECKING DISCORD", isCheckingDiscordMember);
  // console.log("DISCORD JOINED MEMBER", isDiscordJoinedMember);

  return (
    <>
      {!discordUser ? (
        <Button
          variant="outlined"
          color="inherit"
          target="_blank"
          sx={{ my: 1, mx: 1.5, color: "#5865F2", fontWeight: "bold" }}
          endIcon={<DiscordIcon viewBox="0 0 32 32" fill="#5865F2" />}
          onClick={handleDiscordLogin}
        >
          Login with Discord
        </Button>
      ) : (
        <Box>
          <Typography variant="h6">Welcome</Typography>
          <Card sx={{ maxWidth: isMobile ? "100%" : "50%", margin: "16px auto" }}>
            <CardHeader
              avatar={
                <Avatar
                  alt={discordUser.username}
                  src={`https://cdn.discordapp.com/avatars/${discordUser.id}/${discordUser.avatar}.png`}
                />
              }
              action={
                <Button
                  aria-label="Log out"
                  title="Log out"
                  size="small"
                  endIcon={<Logout />}
                  onClick={handleDiscordLogout}
                >
                  Logout
                </Button>
              }
              title={discordUser.username}
              subheader={`#${discordUser.username}`}
            />
            <CardContent>
              <Typography variant="body2" color="text.secondary">
                {isDiscordJoinedMember ? (
                  <Alert color="success">You are a member of The Veil Discord.</Alert>
                ) : (
                  <Alert color="error">
                    You are not a member of The Veil Discord. Please join and try again.
                    <Button
                      variant="text"
                      href={DISCORD_URL}
                      target="_blank"
                      rel="noreferrer"
                      endIcon={<DiscordIcon viewBox="0 0 32 32" fill="#5865F2" />}
                    >
                      Join The Veil Discord
                    </Button>
                    <Button
                      sx={{ mt: 1 }}
                      variant="outlined"
                      color="secondary"
                      onClick={handleDiscordIsMemberCheck}
                      size="small"
                      startIcon={
                        isCheckingDiscordMember ? <CircularProgress size={20} /> : <Refresh />
                      }
                      disabled={isCheckingDiscordMember}
                    >
                      {isCheckingDiscordMember ? "Checking..." : "Check Member Status"}
                    </Button>
                  </Alert>
                )}
              </Typography>
            </CardContent>
          </Card>
        </Box>
      )}
    </>
  );
}
