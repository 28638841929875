import { CircularProgress, Container, Typography, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import styles from "./styles";
import { useEffect } from "react";

import storage from "../../utils/sessionStorage";
import api from "../../utils/api";
import WhitelistStepper from "./Stepper";
import useWhitelistStore from "./store";

const WhitelistFormIndex = (props) => {
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up("md"));
  const formIsLoading = useWhitelistStore((state) => state.formIsLoading) ?? false;

  useEffect(() => {
    useWhitelistStore.setState({ formIsLoading: true });
    const existingMember = storage.getMember();
    const existingToken = storage.getToken();

    if (existingMember && existingToken) {
      api
        .checkToken(existingToken.access_token)
        .then((response) => {
          storage.setMember(response.data.user);
          useWhitelistStore.setState({
            discordUser: response.data.user,
            discordIsMember: response.data.isMemberOfServer,
            stepData: {
              ...useWhitelistStore.getState().stepData,
              isDiscordJoinedMember: response.data.isMemberOfServer,
              isDiscordVerified: true,
            },
            activeStep: 1,
          });
          return;
        })
        .catch(() => {
          useWhitelistStore.getState().resetStore();
          storage.clearStorageValues();
        })
        .finally(() => {
          useWhitelistStore.setState({ formIsLoading: false });
        });
    }

    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    if (code) {
      api
        .authenticate(code)
        .then((response) => {
          if (response.data.user) {
            storage.setMember(response.data.user);
            storage.setToken(response.data.token);
            useWhitelistStore.setState({
              discordUser: response.data.user,
              discordIsMember: response.data.isMemberOfServer,
              stepData: {
                ...useWhitelistStore.getState().stepData,
                isDiscordJoinedMember: response.data.isMemberOfServer,
                isDiscordVerified: true,
              },
              activeStep: 1,
            });
          }
          window.history.replaceState({}, document.title, window.location.pathname);
        })
        .catch(() => {
          useWhitelistStore.getState().resetStore();
          storage.clearStorageValues();
        })
        .finally(() => {
          useWhitelistStore.setState({ formIsLoading: false });
        });
    }

    if (!code && !existingMember && !existingToken) {
      useWhitelistStore.setState({ formIsLoading: false });
    }
  }, []);

  return (
    <Container maxWidth={isMobile ? false : "lg"}>
      <Box
        {...props}
        styles={props.styles}
        sx={(theme) => ({ ...styles(theme), ...props.styles })}
        justifyContent="center"
      >
        <Grid container spacing={{ xs: 2, md: 3, lg: 12 }} sx={{ px: isMobile ? 0 : 12 }}>
          <Grid item xs={12}>
            <Typography variant="h4" sx={{ mb: isMobile ? 2 : 6 }}>
              Whitelist Application
            </Typography>
            {formIsLoading ? <CircularProgress /> : <WhitelistStepper />}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default WhitelistFormIndex;
