export default [
  {
    question: "How long have the Rift Events been a part of the world?",
    answer:
      "Since 2010. Our current setting is early 2020, and for nearly the entirety of the 8 years since the first Rift Event in Deer Isle, there have been Rift Events worldwide. These Rift Events outside of Deer Isle are still on-going.",
  },
  {
    question: "So is the entire world in the same state as Deer Isle?",
    answer:
      "Pretty much. There are no more governments or militaries, and for the most part there are no functioning vehicles or organizations. Most attempts at rebuilding any sort of civilization or societies have failed, though surely some small pockets of survivors have had minor successes. Communications over long-range distances are effectively neutered, many satellites have already de-orbited. The world is a lawless and vicious place. Brutality, banditry, and barbarism are quite rampant.",
  },
  {
    question:
      "What caused the first Rift Event, or what causes the Rift Events in general?",
    answer: "This is something to be discovered in-character.",
  },
  {
    question: "Zombies?",
    answer: `While DayZ is a zombie game, in The Veil they should not be thought of as archetypical "zombies", "undead", or any variation that treats them as unliving. They are infected individuals who began acting erratically and violently after the Rift Events spread across the world. Consequently, these infected individuals permeate the globe and have for eight years.
        All characters will have this knowledge.`,
  },
  {
    question: "So why do they look like zombies?",
    answer:
      "It takes a tremendous amount of effort and artistic talent to re-skin every zombie model and make it unique, which is not something we are keen to do. In the meantime, suspension of disbelief is a powerful tool.",
  },
  {
    question: "Chernarus? Namalsk? Livonia? Esseker? Banov? Takistan?",
    answer:
      "Not really. Any experiences on other servers or maps may not be referenced in-character here, unless adapted to fit our lore.",
  },
  {
    question: "What would my character know about the Rift Events?",
    answer:
      "Unless you have been on Deer Isle since the first Rift Event, not much other than they are violent and dangerous events. The exact cause of the Rift Events are unknown to all but a select few actors. However, all characters with access to uncensored news media circa 2010 would know that the first Rift Event occurred in Deer Isle, and signaled the apocalypse.",
  },
  {
    question: "Alchemy? Magic?",
    answer: `A character who was not present on Deer Isle between 2010 and the current date will have no understanding or knowledge of these concepts.
    Characters who were present might have heard of these concepts, but specifics will be foreign and unknown. 
    "Magic" is a simplification of what is occurring in the world of The Veil, but it is very much the simplest way to express that there is some magical shit.
    `,
  },
  {
    question: "My special forces group/PMC…?",
    answer:
      "Your special forces group, military organization, or private military company has been without a command structure for at least seven years. The logistical challenge of supporting and maintaining military organizations of any caliber has been insurmountable. Your group or comrades may still be alive, but have most likely been without logistical or command support for a very long time.",
  },
  {
    question: '"I was sent here by…"',
    answer:
      "This is a question to approach the Lore team about. We will do our best to incorporate any small organizations into the world's lore, but the legwork must be done by you to ensure it fits within the world of The Veil. We aim to be as flexible as possible, with some obvious exceptions and caveats.",
  },
  {
    question: "So… how do I become a(n) Alchemist/Smith/Wizard, etc.?",
    answer: `To address this as bluntly as possible, this is a low fantasy setting. This means that anything considered "magical" is an intrusion upon the world and should not be viewed as an expected  behavior.. The fact that magic exists in any fashion within the world should be a strong shock to most characters who have not been present on Deer Isle since 2010.
    As to learning to become a craftsman of some sort, please consider your character's ability to perform and learn any such skills. A character should not be able to do everything.  Well-developed and thought-out characters will have flaws as well as strengths. Your character not knowing how to do something will allow more opportunities for interactions with other characters who do have the knowledge.`,
  },
  {
    question:
      "I need to tell another player that I have to contact the admins for some reason. How should I do this?",
    answer: `Avoid it if you can, unless it is absolutely necessary. Even if another player is breaking the rules, you should continue to roleplay and follow the rules as best as you can.
        If you must refer to submitting a support ticket in-game, please use a phrase such as "hoping for some divine intervention". When in character please avoid using words such as "Admins", "Gods", "Powers that be", or "Staff" when referring to the Staff team.  This has a negative impact on immersion as well as other things.`,
  },
  {
    question: "I can do X thing in game, so why is it against the rules?",
    answer:
      "Because a major focus of this server is realism. This means behaving, moving, and acting as realistically as possible within the constraints of the game engine. Just because you can do something in the game doesn't mean you should. There are many things possible in DayZ that are impossible in reality. Normally people in real life are not fidgeting or jumping around while having a conversation with someone.",
  },
  {
    question: "My food and water are always low. Why?",
    answer: "You're probably sprinting everywhere and freezing your toes off.",
  },
  {
    question: "Why is it always raining?",
    answer: `Deer Isle is an island off the coast of Maine. It's cold, and it's rainy. The harsh conditions, coupled with fluctuating weather systems due to the state of the world, can make for a more immersive experience.`,
  },
  {
    question:
      "I'd like to be an important character within the lore of the server, or be given lore to disseminate to other characters. How can I start?",
    answer:
      "Once you have prepared a character concept, fleshed out some details, and have played on the server for a bit, open a support ticket with us and we will start working with you to see whether or not the character will fit into what we have already put in place and the ideas of what we think could happen in the future.",
  },
  {
    question:
      'How do you reconcile so many people "showing up" on Deer Isle if the state of the world is so awful?',
    answer: "Because we want people to play on our server.",
  },
];
