import { InputLabel, Typography } from "@mui/material";

function Label(props) {
  return (
    <InputLabel
      {...props}
      sx={{
        textAlign: "left",
        mt: 3,
        borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
      }}
    >
      <Typography variant="subtitle1">{props.children}</Typography>
    </InputLabel>
  );
}

export default Label;
