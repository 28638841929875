import { Typography } from "@mui/material";

const ListItem = ({ children }) => <li style={{ marginBottom: 8 }}>{children}</li>;

export const characterBackstoryInfo = () => (
  <p style={{ maxWidth: "50vw", padding: "0 20px" }}>
    <Typography variant="h6" sx={{ mb: 1 }}>
      Please create a character backstory and include the following:
    </Typography>
    <ul>
      <ListItem>
        1. What is your character's full name? We do not allow famous names from pop culture.
      </ListItem>

      <ListItem>
        2. Where did they grow up? We no longer allow characters to grow up on Deer Isle, Maine, or
        be present during the first rift events, as this gives the character too much of an RP
        advantage and can cause confusing information and/or RP.
      </ListItem>

      <ListItem>3. Provide details of their upbringing and how old they are.</ListItem>

      <ListItem>
        4. Tell us a detailed story about your character so we can understand what kind of person
        they are.
      </ListItem>

      <ListItem>
        5. How have they been surviving all these years since the rift events? All characters must
        have seen and experienced the rifts.
      </ListItem>

      <ListItem>
        6. How and why did your character end up on Deer Isle? Your character should just be
        arriving. We do not allow characters to be away at sea.
      </ListItem>
    </ul>
  </p>
);
