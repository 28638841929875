import { Button, Typography } from "@mui/material";
import { DiscordIcon } from "../../../components/icons";
import { DISCORD_URL } from "../../../constants";

export default function StepOne() {
  return (
    <>
      <Typography variant="body1">
        Welcome to The Veil whitelist application. To apply, you must be a member of{" "}
        <Button
          variant="text"
          href={DISCORD_URL}
          target="_blank"
          rel="noreferrer"
          endIcon={<DiscordIcon viewBox="0 0 32 32" fill="#5865F2" />}
        >
          The Veil Discord
        </Button>{" "}
        then, please fill out the form below, and a member of our team will review your submission.
        If you have any other questions, you can open a support ticket in The Veil Discord.
      </Typography>
    </>
  );
}
