/**
 * Getter function to retrieve the member from sessionStorage
 * @returns {DiscordOauth2.User|null} The member stored in sessionStorage, or null if not found
 */
function getMember() {
  return JSON.parse(sessionStorage.getItem("member"));
}

/**
 * Setter function to store the member in sessionStorage
 * @param {DiscordOauth2.User} member - The member to be stored in sessionStorage
 */
function setMember(member) {
  sessionStorage.setItem("member", JSON.stringify(member));
}

/**
 * Getter function to retrieve the token from sessionStorage
 * @returns {DiscordOauth2.TokenRequestResult|null} The token stored in sessionStorage, or null if not found
 */
function getToken() {
  return JSON.parse(sessionStorage.getItem("token"));
}

/**
 * Setter function to store the token in sessionStorage
 * @param {DiscordOauth2.TokenRequestResult} token - The token to be stored in sessionStorage
 */
function setToken(token) {
  sessionStorage.setItem("token", JSON.stringify(token));
}

/**
 * Function to clear the tokens from sessionStorage
 */
function clearStorageValues() {
  sessionStorage.removeItem("member");
  sessionStorage.removeItem("token");
}

export default { getMember, setMember, getToken, setToken, clearStorageValues };
