import axios from "axios";
import { API_SECRET_TOKEN } from "../constants";

const headers = {
  "x-api-token": API_SECRET_TOKEN,
};

export const apiDiscordLogin = async () =>
  axios.get("/.netlify/functions/discord-login/login", { headers });

export const authenticate = async (code) =>
  axios.get(`/.netlify/functions/discord-login/callback?code=${code}`, { headers });

export const checkToken = async (token) => {
  return axios.get(`/.netlify/functions/discord-login/check-token?token=${token}`, { headers });
};

export const validateBackstory = async ({ characterBackstory }) => {
  return axios.post(
    `/.netlify/functions/whitelist-validations`,
    {
      characterBackstory,
    },
    { headers }
  );
};

export const submitWhitelist = async ({ formData, discordUser }) => {
  return axios.post(
    `/.netlify/functions/whitelist-submission`,
    {
      formData,
      discordUser,
    },
    { headers }
  );
};

export default { authenticate, checkToken, validateBackstory, submitWhitelist };
