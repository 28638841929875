import { useForm } from "react-hook-form";
import useFormPersist from "react-hook-form-persist";
import {
  TextField,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
  Button,
  Typography,
  Box,
  Grid,
  CircularProgress,
  Tooltip,
  IconButton,
  Alert,
  useMediaQuery,
  Popover,
  Checkbox,
  FormGroup,
  FormHelperText,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useEffect, useState } from "react";
import Label from "./components/FormLabel";
import { validateBackstory } from "../../utils/api";
import useWhitelistStore from "./store";
import { CheckCircleOutline } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { characterBackstoryInfo } from "./data/text";

const requiredStyle = {
  color: "red",
  fontSize: "38px",
  lineHeight: "23px",
  verticalAlign: "text-top",
};

const getHelperText = (error, customValidationMessage) => {
  if (!error) return "";
  if (error.type === "validate") return customValidationMessage || "Your answer is not correct.";
  if (error.message) return error.message;
  return "This field is required.";
};

const WhitelistForm = () => {
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up("md"));

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const idPopover = open ? "simple-popover" : undefined;

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState,
    formState: { errors },
  } = useForm();

  useFormPersist("wl-app-form", {
    watch,
    setValue,
  });

  const [charCount, setCharCount] = useState(0);
  const [isChecking, setIsChecking] = useState(false);
  const formIsValid = useWhitelistStore((state) => state.stepData.formIsValid);
  // const formIsSubmitting = useWhitelistStore((state) => state.formIsSubmitting);
  const [backstoryValidation, setBackstoryValidation] = useState(null);

  const onSubmit = async (data) => {
    // console.log("SUBMIT charbackstory", data.characterBackstory);
    setIsChecking(true);
    try {
      const res = await validateBackstory({ characterBackstory: data.characterBackstory });
      // console.log("SUBMIT res", res);
      if (res.data.isValid) {
        useWhitelistStore.getState().setStepData({ formIsValid: true });
        setBackstoryValidation(null);
        useWhitelistStore.getState().setWhitelistFormData(data);
      } else {
        if (res.data.validationPoints) {
          setBackstoryValidation(res.data.validationPoints);
        }

        useWhitelistStore.getState().setStepData({ formIsValid: false });
      }
    } catch (error) {
      console.error("Error validating backstory:", error);
      useWhitelistStore.getState().setStepData({ formIsValid: false });
      setBackstoryValidation([
        "An error occurred while validating the backstory. Please try again.",
      ]);
    } finally {
      setIsChecking(false);
    }
  };

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      // reset();
    }
    const characterBackstory = watch("characterBackstory");
    if (characterBackstory) {
      setCharCount(characterBackstory.trim().split(/\s+/).length);
    }
  }, [formState, reset]);

  const onReset = () => {
    if (confirm("Are you sure want to reset? You will lose all of your progress.")) {
      sessionStorage.removeItem("wl-app-form");
      reset();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Alert
        variant="outlined"
        severity="info"
        sx={{ mb: isMobile ? 2 : 6, color: "#aaa", border: 0, px: 0, textAlign: "left" }}
      >
        <Typography variant="subtitle1">
          Your form will be saved as you type. Please feel free to navigate away and come back if
          you need to.
        </Typography>
      </Alert>
      <Label htmlFor="steamID">
        What is your 17-digit Steam64 ID? <span style={requiredStyle}>*</span>
        <Tooltip
          componentsProps={{
            tooltip: {
              style: { minHeight: 100 },
            },
          }}
          title={
            <>
              Open{" "}
              <a href="https://store.steampowered.com/" target="_blank" rel="noreferrer">
                Steam
              </a>{" "}
              in your web browser or the Steam application and log in. <br />
              <br />
              Click on your username in the top-right corner.
              <br />
              Select &quot;Account details&quot;. <br />
              <br /> Your 17-digit SteamID will be displayed near the top-left of the screen, below
              your Steam username "Steam ID: 7656xxxxxxxx".
            </>
          }
        >
          <IconButton size="small">
            <InfoIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Label>
      <TextField
        id="steamID"
        label=""
        variant="outlined"
        error={!!errors.steamID}
        {...register("steamID", {
          required: "This field is required and must be 17 digits long.",
          minLength: {
            value: 17,
            message: "This field must be 17 digits long.",
          },
          maxLength: {
            value: 17,
            message: "This field must be 17 digits long.",
          },
          pattern: {
            value: /^(?!76561198000000000$|76561197960287930$)765[0-9]{14}$/,
            message: "This does not seem to be a valid Steam64 ID. (ex: 76561198000000000)",
          },
        })}
        helperText={
          errors.steamID
            ? "This does not seem to be a valid Steam64 ID. (ex: 76561198000000000)"
            : ""
        }
        fullWidth
        type="number"
        margin="normal"
        inputProps={{
          inputMode: "numeric",
          pattern: "[0-9]{17}",
          maxLength: 17,
        }}
      />

      <FormLabel component="legend" sx={{ textAlign: "left", mt: 3 }}>
        Have you read the{" "}
        <Button href="https://theveildayz.com/#rules" target="_blank" rel="noreferrer">
          Rules and the Lore
        </Button>
        ? <span style={requiredStyle}>*</span>
      </FormLabel>
      <RadioGroup row {...register("readRules", { required: true })}>
        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
        <FormControlLabel value="No" control={<Radio />} label="No" />
      </RadioGroup>
      {errors.readRules && (
        <Typography color="error" align="left">
          This field is required.
        </Typography>
      )}

      <Label htmlFor="ckMeaning">
        What does &quot;CK&quot; stand for, and how would it affect a character?{" "}
        <span style={requiredStyle}>*</span>
      </Label>
      <TextField
        id="ckMeaning"
        label=""
        multiline
        rows={2}
        variant="outlined"
        error={!!errors.ckMeaning}
        helperText={getHelperText(errors.ckMeaning)}
        {...register("ckMeaning", {
          required: true,
          minLength: {
            message: "This is a two-part question. Please provide both parts.",
            value: 30,
          },
          maxLength: {
            message: "Max length is 200 characters.",
            value: 200,
          },
          validate: (value) => {
            const lowercaseValue = value.toLowerCase();
            return lowercaseValue.includes("character kill");
          },
        })}
        fullWidth
        margin="normal"
        InputLabelProps={{ shrink: false }}
      />

      <Label htmlFor="kosExplanation">
        What does &quot;KOS&quot; mean? Can you name a scenario where KOS is acceptable and a
        scenario where it isn&apos;t? <span style={requiredStyle}>*</span>
      </Label>
      <TextField
        id="kosExplanation"
        label=""
        multiline
        rows={4}
        variant="outlined"
        error={!!errors.kosExplanation}
        helperText={getHelperText(errors.kosExplanation)}
        {...register("kosExplanation", {
          required: true,
          minLength: {
            message: "This is a two-part question. Please provide both parts.",
            value: 30,
          },
          maxLength: {
            message: "Max length is 200 characters.",
            value: 200,
          },
          validate: (value) => {
            const lowercaseValue = value.toLowerCase();
            return (
              lowercaseValue.includes("kill on sight") ||
              lowercaseValue.includes("killing on sight")
            );
          },
        })}
        fullWidth
        margin="normal"
      />

      <Label htmlFor="aiName">
        DayZ is a &quot;zombie&quot; game, but on our server the AI enemies should not be referred
        to as &quot;zombies&quot;. <br /> What should they be referred to as?
        <span style={requiredStyle}>*</span>
      </Label>
      <TextField
        id="aiName"
        label=""
        variant="outlined"
        error={!!errors.aiName}
        helperText={getHelperText(errors.aiName)}
        {...register("aiName", {
          required: true,
          minLength: 8,
          maxLength: 100,
          validate: (value) => value.toLowerCase().includes("infected"),
        })}
        fullWidth
        margin="normal"
      />

      <Label htmlFor="serverDetails">
        What country and locale is the server set in?
        <span style={requiredStyle}>*</span>
      </Label>
      <TextField
        id="serverDetails"
        label=""
        multiline
        rows={4}
        variant="outlined"
        error={!!errors.serverDetails}
        helperText={getHelperText(
          errors.serverDetails,
          "Your answer is not correct. Make sure to provide not just the state and country."
        )}
        {...register("serverDetails", {
          required: true,
          maxLength: 200,
          validate: (value) => {
            const lowercaseValue = value.toLowerCase();
            return lowercaseValue.includes("maine") && lowercaseValue.includes("deer isle");
          },
        })}
        fullWidth
        margin="normal"
      />
      <Grid container spacing={4}>
        <Grid item xs={12} lg={6}>
          <Label htmlFor="firstRiftEvent">
            When was the first Rift Event? <span style={requiredStyle}>*</span>
          </Label>
          <TextField
            id="firstRiftEvent"
            select
            label=""
            variant="outlined"
            error={!!errors.firstRiftEvent}
            helperText={getHelperText(errors.firstRiftEvent)}
            {...register("firstRiftEvent", {
              required: true,
              validate: (value) => value == 2010,
            })}
            margin="normal"
            fullWidth
            SelectProps={{
              native: true,
            }}
          >
            <option value=""></option>
            {[...Array(new Date().getFullYear() - 1990 + 1).keys()].map((year) => (
              <option key={year} value={1990 + year}>
                {1990 + year}
              </option>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Label htmlFor="currentYear">
            What is the current year according to the lore? <span style={requiredStyle}>*</span>
          </Label>
          <TextField
            id="currentYear"
            select
            label=""
            variant="outlined"
            error={!!errors.currentYear}
            helperText={getHelperText(errors.currentYear)}
            {...register("currentYear", {
              required: true,
              validate: (value) => value >= 2020,
            })}
            margin="normal"
            fullWidth
            SelectProps={{
              native: true,
            }}
          >
            <option value=""></option>
            {[...Array(new Date().getFullYear() - 1990 + 1).keys()].map((year) => (
              <option key={year} value={1990 + year}>
                {1990 + year}
              </option>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <Label htmlFor="characterBackstory">
        Please provide a backstory for your character.
        <span style={requiredStyle}>*</span>
        {/* <Tooltip title="Provide a detailed backstory including your character's history, motivations, and any relevant details."> */}
        {/* <Tooltip title={characterBackstoryInfo}>
          <IconButton size="small">
            <InfoIcon fontSize="small" />
          </IconButton>
        </Tooltip> */}
        <IconButton size="small" onClick={handlePopoverClick}>
          <InfoIcon fontSize="small" />
        </IconButton>
        <Popover
          id={idPopover}
          open={openPopover}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          {characterBackstoryInfo()}
        </Popover>
      </Label>
      <TextField
        id="characterBackstory"
        label=""
        multiline
        rows={24}
        variant="outlined"
        error={!!errors.characterBackstory}
        helperText={getHelperText(
          errors.characterBackstory,
          "This field is required. Should be between 300 and 1000 words"
        )}
        {...register("characterBackstory", {
          required: true,
          validate: (value) => {
            const words = value.trim().split(/\s+/).length;
            return words >= 300 && words <= 1000;
          },
        })}
        fullWidth
        margin="normal"
      />
      <Typography variant="body2" color="textSecondary">
        {charCount} / 1000 words
      </Typography>

      <FormGroup>
        <FormControlLabel
          sx={{ justifyContent: "center" }}
          control={
            <Checkbox
              error={!!errors.agreeToNoAI}
              {...register("agreeToNoAI", {
                required: {
                  value: true,
                  message: "You must agree to this statement.",
                },
              })}
            />
          }
          label="I confirm this is my own original work and was not generated using AI."
        />
      </FormGroup>
      {errors.agreeToNoAI && (
        <FormHelperText error sx={{ textAlign: "center" }}>
          {getHelperText(errors.agreeToNoAI)}
        </FormHelperText>
      )}

      {backstoryValidation && (
        <Box
          sx={{
            mt: 2,
            p: 2,
            border: "1px solid #f44336",
            borderRadius: "4px",
            backgroundColor: "#000000",
            textAlign: "left",
          }}
        >
          <Typography variant="h6" color="error">
            Possible issues with your backstory:
          </Typography>
          <ul>
            {backstoryValidation.map((point, index) => (
              <li key={index}>
                <Typography variant="body1" color="error">
                  {point}
                </Typography>
              </li>
            ))}
          </ul>
        </Box>
      )}
      <Typography
        variant="body2"
        color="textSecondary"
        sx={{
          mt: 4,
          pt: 4,
          borderTop: "2px solid rgba(255, 255, 255, 0.2)",
        }}
      >
        Please use the &quot;Check Form&quot; button to validate your form before continuing.
      </Typography>
      <Box
        sx={{
          py: 4,
          borderBottom: "2px solid rgba(255, 255, 255, 0.2)",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          variant="outlined"
          color="error"
          onClick={onReset}
          sx={{ mr: 3 }}
          disabled={isChecking}
        >
          Reset
        </Button>
        {formIsValid ? (
          <Button type="submit" variant="outlined" color="success" disabled={true}>
            <CheckCircleOutline /> Valid
          </Button>
        ) : (
          <Button type="submit" variant="outlined" color="info" disabled={isChecking}>
            {isChecking ? <CircularProgress size={24} /> : "Check Form"}
          </Button>
        )}
      </Box>
    </form>
  );
};

export default WhitelistForm;
