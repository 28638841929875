export default (theme) => ({
  //  height: '90vh',
  background: "#000",
  paddingTop: "91px",
  marginBottom: "90px",
  minHeight: "100vh",
  height: "auto",
  width: "100%",
  display: "flex",
  backgroundRepeat: "no-repeat",
  boxShadow: "inset 0em 0em 2em 20px rgb(0 0 0)",
  [theme.breakpoints.down("md")]: {
    flexWrap: "wrap",
    height: "auto",
    minHeight: "100vh",
    display: "block",
  },
});
