import { List, ListItem, ListItemText, Typography } from "@mui/material";

export default function () {
  const mechanics = [
    "Custom coded AI created by our experienced team of programmers (These are not the same old monsters) For example the Infected vary in strength, speed and aggression depending on many different factors including location, time of day and other surprising elements",
    "Unique sci-fi/low fantasy game elements to explore and engage with, with more being introduced through regular updates",
    "The beautiful Deer Isle map (set in Deer Isle, Maine), with exciting custom created additions to help traverse the expanse of sea and islands such as craftable Rafts and findable Viking Ships and even magical teleportation!",
    "Balanced and custom loot spawns, forget everything you know about where things spawn. (We want the whole world to have meaning)",
  ];
  const lore = [
    "The Veil is a living and breathing world, every action (or inaction) by players affects the overall lore as well as the game environment",
    "A dedicated and veteran Lore Team that encourages collaboration between the team and the players",
    'PDA system in game (that displays the Global PDA channel in the Discord server) and a special text channel that allows players to "talk" on the in game radio (Allowing for time zone differences and continuous character exploration even when RL doesn\'t allow for game play)',
    "Stories, journal entries and more! Help build on the lore of your own character by writing stories, character journals, screenshots and art",
  ];

  const content = [
    'Start a faction! Work with staff (create a lore open-ticket) to create your own custom flags and armbands for your faction. With our system for creating player driven content, groups or individuals can work IC to "build" custom staff approved map edits',
    "New player character species (plural!) These are currently available through extensive roleplay, not yet at character creation. The species include new game mechanics and even enhanced character models",
    "Custom created gear and items by our generous and talented artists (Including custom items that are crafted in game)",
    "Mining and metallurgy, ammunition crafting, herbalism and alchemy and much more to discover!",
  ];

  const assurances = [
    "We have a dedicated and experienced team of veteran roleplayers that make up our staff and community. Staff are available to help at nearly all hours with any issues that may arise (IC, technical issues, lore questions, community issues, etc.) through our ticket system. And the community is friendly and helpful both in game and in the Discord server.",
    "Here on The Veil it is 100% roleplay all the time. No KoS/RDM (except for specially marked zones which offer more rewards for more risk). ",
    "We strive in everything that we do to put our players first by creating a safe and welcoming environment that encourages creativity without fear of toxicity or judgement",
    "Have a suggestion? We want to hear from you! We have a feedback-and-suggestions channel where you can feel free to suggest anything from new modpacks to rules changes.",
  ];
  return (
    <>
      <Typography variant="title" color="primary.main">Game Mechanics</Typography>
      <List>
        {mechanics.map((text) => (
          <ListItem>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
      <Typography variant="title" color="primary.main">Immersive Lore</Typography>
      <List>
        {lore.map((text) => (
          <ListItem>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
      <Typography variant="title" color="primary.main">Custom Content</Typography>
      <List>
        {content.map((text) => (
          <ListItem>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
      <Typography variant="title" color="primary.main">OOC Assurances</Typography>
      <List>
        {assurances.map((text) => (
          <ListItem>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </>
  );
}
