import Layout from "../../components/Layout";
import PhotoGallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { useState, useCallback } from "react";
const styles = {
  justifyContent: "center",
  backgroundSize: "cover",
  paddingTop: "91px",
  flexDirection: "row",
  flexWrap: "wrap",
};
const S3_URL = "https://the-veil.s3.us-west-2.amazonaws.com/gallery/";
const images = [
  {
    src: S3_URL + "crater_1-min.jpg",
    width: 4,
    height: 2,
  },
  {
    src: S3_URL + "knight_swamp-min.png",
    width: 3,
    height: 2,
  },
  {
    src: S3_URL + "prov_mummy-min.jpg",
    width: 3,
    height: 2,
  },
  {
    src: S3_URL + "crater_2-min.png",
    width: 3,
    height: 2,
  },
  {
    src: S3_URL + "raft_one-min.png",
    width: 3,
    height: 2,
  },
  {
    src: S3_URL + "sandy_1-min.jpg",
    width: 4,
    height: 2,
  },
  {
    src: S3_URL + "cutting_biomass.png",
    width: 3,
    height: 2,
  },
  {
    src: S3_URL + "sniper-min.png",
    width: 3,
    height: 2,
  },
  {
    src: S3_URL + "standoff_1-min.png",
    width: 3,
    height: 2,
  },
  {
    src: S3_URL + "vamp_ritual_1-min.png",
    width: 4,
    height: 2,
  },
  {
    src: S3_URL + "sandy_broken_bridge-min.png",
    width: 3,
    height: 2,
  },
  {
    src: S3_URL + "wings.png",
    width: 4,
    height: 2,
  },
  {
    src: S3_URL + "devils_eye_rift.png",
    width: 3,
    height: 2,
  },
  {
    src: S3_URL + "gazebo.jpg",
    width: 3,
    height: 2,
  },
  {
    src: S3_URL + "overlook.png",
    width: 4,
    height: 2,
  },
  {
    src: S3_URL + "rift_cave.png",
    width: 3,
    height: 2,
  },
  {
    src: S3_URL + "diving_walking.png",
    width: 4,
    height: 2,
  },
  {
    src: S3_URL + "running_from_mushrooms.png",
    width: 3,
    height: 2,
  },
  {
    src: S3_URL + "staff_light.png",
    width: 4,
    height: 2,
  },
];
const Gallery = (props) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  return (
    <Layout {...props} styles={styles} header="Gallery">
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <div className="youtube-video">
          <iframe
            src="https://www.youtube.com/embed/WCNu6AaDyws"
            title="The Veil | DayZ RP Server | Official Trailer"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <PhotoGallery margin={12} photos={images} onClick={openLightbox} />
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={images.map((x) => ({
                  ...x,
                  srcset: x.srcSet,
                  caption: x.title,
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
    </Layout>
  );
};

export default Gallery;
