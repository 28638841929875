import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import GlobalStyles from "@mui/material/GlobalStyles";
import CssBaseline from "@mui/material/CssBaseline";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./App.css";
import Home from "./pages/Home";
import Rules from "./pages/Rules/index";
import { AppBar } from "./components/AppBar";
import WhitelistFormIndex from "./pages/Whitelist";
import MetaTags from "./components/MetaTags";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "rgb(146, 95, 170)",
    },
  },
  typography: {
    htmlFontSize: 18,
    fontSize: 16,
    fontFamily: [
      "Raleway",
      "Fredoka",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    body2: {
      fontSize: "1rem",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <Router>
        <MetaTags />
        <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }} />
        <CssBaseline />

        <AppBar />
        <div className="App">
          <Routes>
            <Route path="/rules" element={<Rules />} />
            <Route path="/whitelist-application" element={<WhitelistFormIndex />} />
            <Route path="*" element={<Home />} />
          </Routes>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
