import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Layout from "../../components/Layout";
import { useTheme } from "@mui/material/styles";
import features from "./features-data";
import bgImage from "../../assets/aboutbg.png";
import LabelIcon from "@mui/icons-material/Label";
import WhatToExpect from "./what-to-expect";

const styles = {
  justifyContent: "center",
  background: `url(${bgImage}) no-repeat center`,
  backgroundSize: "cover",
  paddingTop: "91px",
  color: "#232323",
};
const boxStyles = (theme) => ({
  backgroundColor: "rgba(0,0,0,0.93)",
  boxShadow: "0px 1px 10px rgba(0,0,0,0.8)",
  p: 4,
  textAlign: "left",
  borderRadius: "4px",
  height: "90%",
  width: "100%",
  overflowY: "auto",
  color: "white",
  [theme.breakpoints.down("md")]: {
    height: "auto",
  },
});

const About = (props) => {
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Layout {...props} styles={{ ...props.styles, ...styles }} header="About">
      <Grid
        container
        spacing={{ xs: 2, md: 3, lg: 12 }}
        sx={{ px: isMobile ? 3 : 12 }}
      >
        <Grid item xs={12}>
          <Box sx={boxStyles}>
            <Typography variant="h4" sx={{ mb: 2 }} color="primary.main">
              The Veil
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Story and lore-driven DayZ Roleplay Server with imaginative
              low-fantasy and sci-fi elements, created and developed by roleplay
              veterans.
              <br />
              <br /> The sphere of roleplay is niche, but filled with passionate
              individuals who are typically not content with the coastal
              massacres and violently brief encounters that comprise the vanilla
              DayZ experience. The Veil was created by a group of friends who
              all share a passion for storytelling, and collectively have tens
              of thousands of hours spent immersing themselves across different
              mediums. Our aim with The Veil is to enable you to tell the
              stories you want to tell, engage with others' stories, and
              experience a world rich in lore and atmosphere. We'll set the
              stage, but we welcome all of the actors and the parts they have
              yet to play.
            </Typography>
            <Typography variant="h4" sx={{ mb: 2 }} color="primary.main">
              Lore
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              When the rifts began to open, the smell of death permeated the air
              and fear took on a face that could not be ignored. In my quest for
              a safe haven, I happened upon an island that held the promise of
              prosperity. A community had begun to build and create a place for
              themselves to beat and survive the incoming infestation. I only
              hope that I can lend my skills and help to build what they have
              started. I have my reasons for settling here, but why have you
              come to The Veil?
            </Typography>
            <Typography variant="h4" color="primary.main" sx={{ mb: 3 }}>
              What To Expect
            </Typography>
            <WhatToExpect />
            {/* <Typography variant="h4" color="primary.main">
              Features
            </Typography>
            <List dense>
              {features.map((feature) => (
                <ListItemButton key={feature}>
                  <ListItemIcon sx={{ color: theme.palette.primary.main }}>
                    <LabelIcon />
                  </ListItemIcon>
                  <ListItemText primary={feature} />
                </ListItemButton>
              ))}
            </List> */}
          </Box>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default About;
