import Top from "./Home/Top";
import Gallery from "./Home/Gallery";
import About from "./Home/About";
import Rules from "./Rules";
import FAQs from "./Home/FAQs";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const Home = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const id = location.hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  return (
    <>
      <Top id="top" />
      <Gallery id="gallery" />
      <About id="about" />
      <Rules id="rules" />
      <FAQs id="faqs" />
    </>
  );
};

export default Home;
